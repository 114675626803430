import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import UserService from '../../../../services/user-service';
import './custom.css';
import { IMGURL } from '../../../../config/constant'

const DrawerR = ({ onClose, logout, user, imgname, avat }) => {
  const navigate = useNavigate();
  const [fil, setFil] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [userData, setUserData] = useState([]);


  useEffect(() => {
    if (user) {
      setUserData(user);
    } else return;
  }, [user]);

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSelectImage = (event) => {
    const file = event.target.files[0];
    setFil(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      setPreviewImage(fileReader.result);
    });
    fileReader.readAsDataURL(file);
  };

  const handleUploadImage = () => {
    if (fil) {
      UserService.uploadFile(fil)
        .then((response) => {
          setUploadedImage(response.data);
          updateUserImg(response.data.fileName);
          console.log(response.data);

        })
        .catch((e) => {
          console.log(e);
        });
    } else alert('nofile');
  };

  const updateUserImg = async (name) => {
    var data = {photoUrl: name, id: user.id}
    await UserService.updateUserImg(data)
      .then((response) => {
        console.log(response.data);
        alert('picture updated successfully')
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateUserDetails = async () => {
    var data = {longName: userData.longName, email: userData.email, phone: userData.phone, id: user.id}
    await UserService.updateUser(data)
      .then((response) => {
        console.log(response.data);
        alert('details updated successfully')
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="card">
      <PerfectScrollbar style={{ overflowY: 'scroll', height: 'calc(100vh - 10px)' }}>
        <div className="row gutters">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-header">
                <Row>
                  <Col md={6}>
                    <Link to="#" className="dud-logout" title="Logout" onClick={logout}>
                      <i className="feather icon-log-out text-dark" style={{ fontSize: '20px' }} />
                    </Link>
                  </Col>
                  <Col md={6} className="text-end">
                    <Link
                      to="#"
                      className="dud-logout"
                      title="close"
                      onClick={() => {
                        setPreviewImage(null);
                        onClose();
                      }}
                    >
                      <i className="feather icon-x text-dark" style={{ fontSize: '20px' }} />
                    </Link>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <div className="account-settings">
                  <div className="user-profile">
                    {/* <div className="user-avatar">
                      <img src={avat} alt="Profile" />
                    </div> */}
                    <div className="upload mb-3">
                      {previewImage ? (
                        <img src={previewImage} style={{ width: 100, height: 100 }} alt="" />
                      ) : uploadedImage ? (
                        <img src={uploadedImage} style={{ width: 100, height: 100 }} alt="" />
                      ) : imgname ? (
                        <img src={IMGURL + imgname} style={{ width: 100, height: 100 }} alt="" />
                      ) : (
                        <img src={avat} style={{ width: 100, height: 100 }} alt="" />
                      )}
                      <div className="round">
                        <input type="file" onChange={handleSelectImage} />
                        <i className="fa fa-camera" style={{ color: '#fff' }}></i>
                      </div>
                    </div>
                    {previewImage && (
                      <button type="button" className="btn btn-outline-info ms-3 p-1" onClick={handleUploadImage}>
                        upload Image
                      </button>
                    )}
                    <h5 className="user-name">{userData.longName}</h5>
                    <h6 className="user-email">{userData.title}</h6>
                  </div>
                  {/* <div className="about">
                    <h5>About</h5>
                    <p>I'm Yuki. Full Stack Designer I enjoy creating user-centric, delightful and human experiences.</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="px-3">
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h6 className="mb-2 text-primary">Personal Details</h6>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group pb-2">
                    <label className="lb-md">Full Name</label>
                    <input
                      type="text"
                      className="form-control form-ins1"
                      id="longName"
                      name="longName"
                      placeholder="Enter full name"
                      value={userData.longName}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="lb-md">Email</label>
                    <input
                      type="email"
                      className="form-control form-ins1"
                      id="email"
                      name="email"
                      placeholder="Enter email ID"
                      value={userData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="lb-md">Phone</label>
                    <input
                      type="text"
                      className="form-control form-ins1"
                      id="phone"
                      name="phone"
                      placeholder="Enter phone number"
                      value={userData.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="lb-md">Status</label>
                    <input
                      type="url"
                      className="form-control form-ins1"
                      id="status"
                      name="status"
                      placeholder="status"
                      value={userData.status === 'I' ? 'INACTIVE' || userData.status === 'A' : 'ACTIVE'}
                    />
                  </div>
                </div>
                {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label>Company Id</label>
                      <input type="url" className="form-control" id="cmpId" placeholder="cmpId" value={currUser.cmpId} />
                    </div>
                  </div> */}
              </div>
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h6 className="mt-3 mb-2 text-primary">Address</h6>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="lb-md">Street</label>
                    <input type="name" className="form-control form-ins1" id="Street" placeholder="Enter Street" />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="lb-md">City</label>
                    <input type="name" className="form-control form-ins1" id="ciTy" placeholder="Enter City" />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="lb-md">State</label>
                    <input type="text" className="form-control form-ins1" id="sTate" placeholder="Enter State" />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="lb-md">Zip Code</label>
                    <input type="text" className="form-control form-ins1" id="zIp" placeholder="Zip Code" />
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="text-start">
                    <button type="button" className="btn btn-outline-danger p-1" onClick={() => navigate('/auth/change-password')}>
                      Forgot Password ?
                    </button>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-outline-primary p-1"
                      onClick={() => {
                        setPreviewImage(null);
                        onClose();
                      }}
                    >
                      Close
                    </button>
                    <button type="button" className="btn btn-outline-info ms-3 p-1" onClick={updateUserDetails}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default DrawerR;
