import React, { createContext, useEffect, useReducer } from 'react';
// import jwtDecode from 'jwt-decode';

import { ACCOUNT_INITIALISE, LOGIN, LOGOUT } from '../store/actions';
// import axios from '../services/axios';
import axios from 'axios';
import accountReducer from '../store/accountReducer';
import Loader from '../components/Loader/Loader';

// const API_URL = "http://localhost:8440/api/auth/";
const API_URL = "https://truesoko.com:8440/api/auth/";

// const USER_URL = "http://localhost:8440/api/usr/user/"
// const USER_URL = "https://marketsalesapp.com:8440/api/usr/user/";

const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  user: null
};

// const verifyToken = (serviceToken) => {
//   if (!serviceToken) {
//     return false;
//   }

//   const decoded = jwtDecode(serviceToken);
//   return decoded.exp > Date.now() / 1000;
// };

const setSession = (user) => {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
    axios.defaults.headers.common.Authorization = `Bearer ${user.token}`;
  } else {
    localStorage.removeItem('user');
    localStorage.removeItem('cmpId');
    localStorage.removeItem('companyName');
    delete axios.defaults.headers.common.Authorization;
  }
};

const JWTContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => {}
});

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  const login = async (username, password) => {
    const response = await axios.post(API_URL + 'signin', { username, password });
    setSession(response.data);
    const user = response.data;
    dispatch({
      type: LOGIN,
      payload: {
        user
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  useEffect(() => {
    const init = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.accessToken) {       
          setSession(user);
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: false,
            user: null
          }
        });
      }
    };

    init();
  }, []);

  if (!state.isInitialised) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
