import axios from 'axios';
import authHeader from './auth-header';
import http from './http-common';

const API_URL = "https://truesoko.com:8440/api/usr/";
// const API_URL = 'http://localhost:8440/api/usr/';

const getUserById = (id) => {
  return axios.get(API_URL + 'user/' + id, { headers: authHeader() });
};

const getUsers = () => {
  return axios.get(API_URL + 'user', { headers: authHeader() });
};

const postUser = (data) => {
  return axios.post(API_URL + 'user', data, { headers: authHeader() });
};

const getCompById = (id) => {
  return axios.get(API_URL + 'company/' + id, { headers: authHeader() });
};

const getCompanies = () => {
  return axios.get(API_URL + 'company', { headers: authHeader() });
};

const postComp = (data) => {
  return axios.post(API_URL + 'company', data, { headers: authHeader() });
};

const getSystemById = (id) => {
  return axios.get(API_URL + 'system/' + id, { headers: authHeader() });
};

const getSystems = () => {
  return axios.get(API_URL + 'system', { headers: authHeader() });
};

const postSystem = (data) => {
  return axios.post(API_URL + 'system', data, { headers: authHeader() });
};

const postUserComp = (data) => {
  return axios.post(API_URL + 'ucompany', data, { headers: authHeader() });
};

// const getUserCompbk = (data) => {
//   return axios.get(API_URL + 'ucompany/', data, { headers: authHeader() });
// };

const getUserComp = (id) => {
  return axios.get(API_URL + 'user-companies?userId=' + id, { headers: authHeader() });
};

const postUserSystems = (data) => {
  return axios.post(API_URL + 'user-systems', data, { headers: authHeader() });
};

const getCompSystems = () => {
  return axios.get(API_URL + 'company-systems', { headers: authHeader() });
};

const getUserCompSystems = () => {
  return axios.get(API_URL + 'user-company-systems', { headers: authHeader() });
};

const getUserCompUSystems = () => {
  return axios.get(API_URL + 'user-company-usystems', { headers: authHeader() });
};

const getCustomerById = (id) => {
  return axios.get(API_URL + 'customer/' + id, { headers: authHeader() });
};

const getCustomers = () => {
  return axios.get(API_URL + 'customerIntermediary/Y', { headers: authHeader() });
};

const getAciveCustomers = () => {
  return axios.get(API_URL + 'customerActive/Y', { headers: authHeader() });
};

const postCustomer = (data) => {
  return axios.post(API_URL + 'customer', data, { headers: authHeader() });
};

const putCustomer = (id, data) => {
  return axios.put(API_URL + 'customer/' + id, data, { headers: authHeader() });
};

const getEmployeeById = (id) => {
  return axios.get(API_URL + 'chr_employee/' + id, { headers: authHeader() });
};

const getEmployeeByUsername = (name) => {
  return axios.get(API_URL + 'chr_by_username/' + name, { headers: authHeader() });
};

const getEmployees = () => {
  return axios.get(API_URL + 'chr_employee', { headers: authHeader() });
};

const getPagedEmployees = (offset, pageSize) => {
  return axios.get(API_URL + 'employee/' + offset + '/' + pageSize, { headers: authHeader() });
};

const postEmployee = (data) => {
  return axios.post(API_URL + 'employee', data, { headers: authHeader() });
};

const putEmployee = (id, data) => {
  return axios.put(API_URL + 'employee/' + id, data, { headers: authHeader() });
};

const getClassification = () => {
  return axios.get(API_URL + 'classification', { headers: authHeader() });
};

const getGeneral = () => {
  return axios.get(API_URL + 'general', { headers: authHeader() });
};

const postTempBranch = (data) => {
  return axios.post(API_URL + 'tbranch', data, { headers: authHeader() });
};

const getTempBranch = () => {
  return axios.get(API_URL + 'tbranch', { headers: authHeader() });
};

const saveInvoice = (invoice) => {
  return axios.post(API_URL + 'SaveInvoice', invoice, { headers: authHeader() });
};

const getInvoiceInfo = () => {
  return axios.get(API_URL + 'getInfo', { headers: authHeader() });
};

const postQuote = (data) => {
  return axios.post(API_URL + 'icr_quotes', data, { headers: authHeader() });
};

const getReturnDate = (data) => {
  return axios.get(
    API_URL + 'return_date?startDate=' + data.startDate + '&appliedDays=' + data.appliedDays + '&leaveCode=' + data.leaveCode
    +'&cmpId=' + data.cmpId,
    { headers: authHeader() }
  );
};

const getQuoteNo = (data) => {
  return axios.get(API_URL + 'quote_no?cmpId=' + data.cmpId + '&transType=' + data.transType, { headers: authHeader() });
};

const getQuotes = (data) => {
  return axios.get(API_URL + 'icr_quotes/' + data.cmpId + '/' + data.quoteStatus, { headers: authHeader() });
};

const getIcrProducts = (cmpId) => {
  return axios.get(API_URL + 'icr_product_native?cmpId=' + cmpId, { headers: authHeader() });
};

const getIcrProductCat = (cmpId) => {
  return axios.get(API_URL + 'icr_product_cat?cmpId=' + cmpId, { headers: authHeader() });
};

const createRisks = (data) => {
  return axios.post(API_URL + 'icr_quote_risks', data, { headers: authHeader() });
};

const createSections = (data) => {
  return axios.post(API_URL + 'icr_quote_risksection', data, { headers: authHeader() });
}; 

const getProductSections = (data) => {
  return axios.get(API_URL + 'icr_product_section_native?pCode=' + data.code + '&cmpId=' + data.id, { headers: authHeader() });
};

const createProduct = (data) => {
  return axios.post(API_URL + 'icr_products', data, { headers: authHeader() });
};

const createProductSection = (data) => {
  return axios.post(API_URL + 'icr_product_sections', data, { headers: authHeader() });
};

const updatePass = (data) => {
  return axios.patch(API_URL + 'changepwd', data, { headers: authHeader() });
};

const updateUser = (data) => {
  return axios.get(API_URL + 'update-user-profile?id=' + data.id + '&longName=' + data.longName + '&email=' + data.email + '&phone=' + data.phone, { headers: authHeader() });
};

const updateUserImg = (data) => {
  return axios.get(API_URL + 'update-user-image?id=' + data.id + '&photoUrl=' + data.photoUrl, { headers: authHeader() });
};

// const uploadFile = (file, onUploadProgress) => {
//   let formData = new FormData();

//   formData.append("file", file);
//   return axios.post(API_URL + "singleUpload/", formData, { headers: authHeader(), onUploadProgress })}

// const getFiles = () => {
//   return axios.get(API_URL + "files/", { headers: authHeader() })}

const uploadFile = (file) => {
  let formData = new FormData();

  formData.append('file', file);

  return http.post('/singleUpload', formData, {
    headers: authHeader()
  });
};

const uploadExcel = (file) => {
  let formData = new FormData();

  formData.append('file', file);

  return http.post('/upload_chr_employees', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// const getFiles = (fileName) => {
//   return http.get(API_URL + "download/" + fileName, { headers: authHeader() })}

// const getFiles = () => {
//   return http.get('/files');
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUserById,
  getUsers,
  postUser,
  updateUser,
  updateUserImg,
  getCompById,
  getCompanies,
  postComp,
  getSystemById,
  getSystems,
  postSystem,
  postUserComp,
  postUserSystems,
  getUserComp,
  getCompSystems,
  getUserCompSystems,
  getUserCompUSystems,
  getCustomerById,
  getCustomers,
  getAciveCustomers,
  postCustomer,
  putCustomer,
  getEmployeeById,
  getEmployeeByUsername,
  getEmployees,
  getPagedEmployees,
  postEmployee,
  putEmployee,
  getClassification,
  getGeneral,
  // getFiles,
  saveInvoice,
  getInvoiceInfo,
  getReturnDate,
  uploadFile,
  uploadExcel,
  postTempBranch,
  getTempBranch,
  getQuoteNo,
  postQuote,
  getQuotes,
  getIcrProducts,
  createRisks,
  createSections,
  getProductSections,
  getIcrProductCat,
  createProduct,
  createProductSection,
  updatePass
};
