import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

const AuthGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const cmpId = JSON.parse(localStorage.getItem('cmpId'));
  const qz = JSON.parse(localStorage.getItem('q'));

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/auth/signin-2?q=${qz}`, { replace: true });
    }
    else {
      if(isLoggedIn && !cmpId && !qz){
        navigate('/auth/tabs-auth', { replace: true });
      }
    }
  }, [isLoggedIn, navigate, cmpId, qz]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
