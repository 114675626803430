const menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'SETUP',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-home',
          url: '/app/dashboard/analytics',
          breadcrumbs: false
        },
        {
          id: 'product',
          title: 'Products',
          type: 'item',
          icon: 'feather icon-check-square',
          url: '/product/manage-products',
          breadcrumbs: false

        },
      ]
    },
    {
      id: 'process',
      title: 'PROCESS',
      type: 'group',
      icon: 'icon-support',
      children: [
        {
          id: 'quote',
          title: 'Quotes',
          type: 'item',
          icon: 'feather icon-file-text',
          url: '/quote/quote-dash',
          breadcrumbs: false

        },
        {
          id: 'invoice',
          title: 'Invoices',
          type: 'item',
          url: '/sample-page',
          classes: 'nav-item',
          icon: 'feather icon-sidebar'
        },
      ]
    }
  ]
};

export default menuItems;
